html, body {
  height: 100%;
  margin: 0;
}

body {
  background-color: #393534;
  color: white;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 400px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.error {
  color: #ff0000;
}

.button.button-custom,
button.button-custom,
input[type="submit"].button-custom,
input[type="reset"].button-custom,
input[type="button"].button-custom {
  color: #FFF;
  background-color:#ff9900;
  border-color: #ff9900;
}

.button.button-custom:hover,
button.button-custom:hover,
input[type="submit"].button-custom:hover,
input[type="reset"].button-custom:hover,
input[type="button"].button-custom:hover,
.button.button-custom:focus,
button.button-custom:focus,
input[type="submit"].button-custom:focus,
input[type="reset"].button-custom:focus,
input[type="button"].button-custom:focus {
  color: #FFF;
  background-color: #ffba53;
  border-color: #ff9900;
}

.button.button-custom:disabled,
button.button-custom:disabled,
input[type="submit"].button-custom:disabled,
input[type="reset"].button-custom:disabled,
input[type="button"].button-custom:disabled {
  color: #666;
  background-color: #333;
  border-color: #333;
}

input[type='color'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='month'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='url'],
  input[type='week'],
  input:not([type]),
  textarea,
  select,
  option {
    color: white !important;
    background-color: #393534 !important;
  }

  select {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 8" width="30"><path fill="grey" d="M0,0l6,8l6-8"/></svg>') center right no-repeat !important;
    padding-right: 3.0rem;
  }

  input[type='color']:focus,
  input[type='date']:focus,
  input[type='datetime']:focus,
  input[type='datetime-local']:focus,
  input[type='email']:focus,
  input[type='month']:focus,
  input[type='number']:focus,
  input[type='password']:focus,
  input[type='search']:focus,
  input[type='tel']:focus,
  input[type='text']:focus,
  input[type='url']:focus,
  input[type='week']:focus,
  input:not([type]):focus,
  textarea:focus,
  select:focus {
    border-color: #ff9900 !important;
  }
