html, body {
  height: 100%;
  margin: 0;
}

body {
  color: #fff;
  background-color: #393534;
}

.tooltip {
  border-bottom: 1px dotted #000;
  display: inline-block;
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  color: #fff;
  text-align: center;
  z-index: 1;
  background-color: #000;
  border-radius: 6px;
  width: 400px;
  padding: 5px;
  position: absolute;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.error {
  color: red;
}

.button.button-custom, button.button-custom, input[type="submit"].button-custom, input[type="reset"].button-custom, input[type="button"].button-custom {
  color: #fff;
  background-color: #f90;
  border-color: #f90;
}

.button.button-custom:hover, button.button-custom:hover, input[type="submit"].button-custom:hover, input[type="reset"].button-custom:hover, input[type="button"].button-custom:hover, .button.button-custom:focus, button.button-custom:focus, input[type="submit"].button-custom:focus, input[type="reset"].button-custom:focus, input[type="button"].button-custom:focus {
  color: #fff;
  background-color: #ffba53;
  border-color: #f90;
}

.button.button-custom:disabled, button.button-custom:disabled, input[type="submit"].button-custom:disabled, input[type="reset"].button-custom:disabled, input[type="button"].button-custom:disabled {
  color: #666;
  background-color: #333;
  border-color: #333;
}

input[type="color"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="email"], input[type="month"], input[type="number"], input[type="password"], input[type="search"], input[type="tel"], input[type="text"], input[type="url"], input[type="week"], input:not([type]), textarea, select, option {
  color: #fff !important;
  background-color: #393534 !important;
}

select {
  padding-right: 3rem;
  background: url("data:image/svg+xml;utf8,<svg xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 30 8\" width=\"30\"><path fill=\"grey\" d=\"M0,0l6,8l6-8\"/></svg>") 100% no-repeat !important;
}

input[type="color"]:focus, input[type="date"]:focus, input[type="datetime"]:focus, input[type="datetime-local"]:focus, input[type="email"]:focus, input[type="month"]:focus, input[type="number"]:focus, input[type="password"]:focus, input[type="search"]:focus, input[type="tel"]:focus, input[type="text"]:focus, input[type="url"]:focus, input[type="week"]:focus, input:not([type]):focus, textarea:focus, select:focus {
  border-color: #f90 !important;
}
/*# sourceMappingURL=index.b5150ede.css.map */
